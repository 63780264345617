<template>
  <vx-card class="accordin-card mt-5">
    <vs-collapse accordion>
      <vs-collapse-item ref="termsInformation" :open="true">
        <div slot="header">
          <h3 class="text-left font-normal">Terms and Conditions<span class="mid-blue">*</span></h3>
        </div>
        <div class="flex flex-row">
          <div>
            <vs-checkbox name="productTerm" id="productTerm" data-vv-as="Terms and Conditions" @input="checkForm" v-validate="'required'" v-model="productTerm"></vs-checkbox>
          </div>
          <div>
            <p v-html="latestTerms.explicitTerms"></p>
            <span class="text-danger text-sm" v-show="errors.has('productTerm')">{{ errors.first("productTerm") }}</span>
          </div>
        </div>
      </vs-collapse-item>
    </vs-collapse>
  </vx-card>
</template>
<script>
  import { mapActions } from "vuex";
  import { Validator } from "vee-validate";
  export default {
    props: ["termsDetail"],
    data() {
      return {
        latestTerms: "",
        appUrl: process.env.VUE_APP_ROOT_API,
        otherLegals: {},
        productTerm: null,
      }
    },
    methods: {
      ...mapActions("legals", ["fetchAllLegals"]),
      getLegals() {
        this.$vs.loading();
        this.fetchAllLegals("DASHBOARD").then((response) => {
          this.otherLegals = response.data.data;
          const findCustomerLegal = this.termsDetail.filter((item) => item.user == "CUSTOMER");
          this.latestTerms = findCustomerLegal[findCustomerLegal.length - 1];
          this.latestTerms.explicitTerms = this.replaceLegalContent(
            this.latestTerms.explicitTerms,
            {
              fullTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.latestTerms._id}?type=PAY_LATER_LEGAL&fullDocument=true" target="_blank">${this.latestTerms.documentName}</a>`,
              privacy: `<a href="${this.appUrl}pages/terms-and-conditions/${this.otherLegals.privacy._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.otherLegals.privacy.documentName}</a>`,
              generalWebsiteTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.otherLegals.websiteTerms._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.otherLegals.websiteTerms.documentName}</a>`,
            }
          );
          this.$vs.loading.close();
        }).catch((ex) => {
          this.$vs.loading.close();
        });
      },
      validateChildForm() {
        return this.$validator.validateAll();
      },
      checkForm() {
        this.$emit("checkForm");
      },
    },
    mounted() {
      this.getLegals();
    },
    watch: {
      productTerm(val) {
        if (val) {
          const data = {
            val: val,
            terms: this.otherLegals.websiteTerms._id,
            privacy: this.otherLegals.privacy._id,
          };
          this.$emit('on', data);
          return;
        }
        this.productTerm = null;
      }
    }
  }
</script>