<template>
  <div class="req-temp-form payment-request-form">
    <form @submit.prevent="createTemplate" enctype="multipart/form-data">
      <vs-row id="profile-page">
        <!-- Request details -->
        <vs-card v-if="isLoaded" class="accordin-card mt-5">
          <vs-collapse accordion>
            <vs-collapse-item ref="requestDetails" :open="true">
              <div slot="header">
                <h3 class="text-left font-normal">
                  Request details<span class="mid-blue">*</span>
                </h3>
              </div>

              <div>
                <div>
                  <vs-row>
                    <div class="input-grp flex-col mb-4 md:mb-8">
                      <vs-row>
                        <p class="text-base mb-0">Template</p>
                      </vs-row>
                      <vs-row>
                        <label class="text-lg font-normal mb-0 w-full">
                          {{ requestTemplate.templateName }}
                        </label>
                      </vs-row>
                    </div>
                    <div class="input-grp flex-col mb-4 md:mb-8">
                      <p v-if="!isRecurring" class="text-base">
                        <vs-row> Payment option </vs-row>
                        <vs-row>
                          <label v-if="hasPayNow" class="w-auto">Pay Now</label>
                          <label v-if="hasPayLater"><span v-if="hasPayNow"> &nbsp;/ </span>
                            <a @click="openPopUp('paylater')" class="mid-blue text-base">Pay Later</a>
                          </label>
                        </vs-row>
                      </p>

                      <p v-if="isRecurring" class="text-base">
                        <vs-row> Payment option </vs-row>
                        <vs-row>
                          <a @click="openPopUp('recurring')" class="mid-blue">Recurring</a>
                        </vs-row>
                      </p>
                    </div>
                  </vs-row>

                  <!-- second row -->
                  <vs-row>
                    <div class="input-grp mb-4 lg:mb-0" ref="amount">
                      <label class="text-lg font-normal w-full">{{ isRecurring ? "Recurring amount" : "Amount" }} <span class="mid-blue">*</span></label>

                      <money
                        class="moneyPR"
                        v-model="amount"
                        name="amount"
                        :precision="2"
                        v-validate="rules"
                        v-bind="money2"
                        :placeholder="'Amount'"
                        id="amount"
                      ></money>

                      <span class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first("amount") }}</span>
                      <span class="text-danger text-sm" v-if="showExtraConditionError">{{ errorMsg }}</span>
                    </div>
                    <div class="input-grp mb-4 sm:mb-0">
                      <label class="text-lg font-normal w-full">Reference <span class="mid-blue">*</span></label>

                      <vs-input class="w-full" v-validate="'required'" size="large" name="reference" id="reference" v-model="reference" />
                      <span class="text-danger text-sm" v-show="errors.has('reference')">{{ errors.first("reference") }}</span>
                    </div>
                  </vs-row>
                </div>
              </div>

              <!-- popup starts-->
              <vs-popup id="holamundo" class="config-popup paymentSummaryPopup" :title="'Payment schedule'" :active.sync="popupActivo">
                <div class="w-full">
                  <vs-col vs-w="12" v-if="!isRecurring && activePlan.productType == 'B2C_BNPL'">
                    <vs-row class="flex justify-between pay-later-card lg:p-4 md:p-3 p-2 items-start">
                      <div class="flex flex-col justify-center items-center">
                        <img :src="update" alt="update" />
                        <span class="dark-blue text-xs mt-2"> Every</span>
                        <span class="dark-blue text-xs">{{ `1 ${weekOrMonthOrDay}` }}</span>
                      </div>
                      <div class="flex flex-col justify-center items-center">
                        <img :src="playCircle" alt="playcircle" />
                        <span class="dark-blue text-xs mt-2">Starts on</span>
                        <span class="dark-blue text-xs">acceptance date</span>
                      </div>
                      <div class="flex flex-col justify-center items-center">
                        <img :src="stopCircle" alt="stopcircle" />
                        <span class="dark-blue text-xs mt-2"> Ends after</span>
                        <span class="dark-blue text-xs">{{ `${activePlan.noOfPayments} payments` }}</span>
                      </div>
                    </vs-row>
                    <vs-table :data="paymentSchedules" class="payment-schedule-table">
                      <template slot="thead">
                        <vs-th class="text-base"> Payment date</vs-th>
                        <vs-th class="text-base"> Amount</vs-th>
                      </template>
                      <template slot-scope="{ data }">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].value">
                            {{ formatScheduleDate(data[indextr].value) }}
                          </vs-td>
                          <vs-td class="sec-row-payment" :data="data[indextr].label">
                            {{ data[indextr].label !== "0.00" ? `$ ${data[indextr].label}` : "-" }}
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                    <div class="b2c">
                      <vs-row class="flex justify-between">
                        <span class="text-lg dark-blue">Total amount</span>
                        <span class="text-lg dark-blue">{{ this.amount ? `$ ${this.totalCustomerPayable}` : "-" }}</span>
                      </vs-row>
                    </div>
                  </vs-col>
                  <vs-col vs-w="12" v-if="!isRecurring && (activePlan.productType == 'B2C_BNPO' || activePlan.productType == 'B2C_BNPO_NC')">
                    <div class="gray mb-5 items-center flex">
                      <img :src="calenderImage" alt="calenderImage" />
                      <p class="dark-blue text-base font-normal ml-4">
                        {{ activePlan.productText }}
                      </p>
                    </div>
                    <div class="b2c">
                      <vs-row class="flex justify-between">
                        <span class="text-lg dark-blue">Customer pays</span>
                        <span class="text-lg amount">{{ this.totalCustomerPayable ? `$ ${addZeroes(this.totalCustomerPayable)}` : "-" }}</span>
                      </vs-row>
                      <vs-row class="mt-4">
                        <span class="text-lg dark-blue">Merchant receives</span>
                        <span class="text-lg amount">{{ this.merchantAmount ? `$ ${addZeroes(this.merchantAmount)}` : "-" }}</span>
                      </vs-row>
                    </div>
                  </vs-col>
                  <vs-col vs-w="12" class="" v-if="isRecurring">
                    <vs-row class="flex justify-between pay-later-card lg:p-4 md:p-3 p-2 items-start">
                      <div class="flex flex-col justify-center items-center">
                        <img :src="update" alt="update" />
                        <div v-if="frequency.period">
                          <span class="dark-blue text-xs mt-2"> Every</span>
                          <span class="dark-blue text-xs">{{ `${` `} ${frequency.frequency} ${` `} ${frequency.period}` }}</span>
                        </div>
                        <div v-else>
                          <span class="dark-blue text-xs mt-2 text-center block">Please select payment options first</span>
                        </div>
                      </div>
                      <div class="flex flex-col justify-center items-center">
                        <img :src="playCircle" alt="playcircle" />
                        <div v-if="frequency.startDate">
                          <span class="dark-blue text-xs mt-2">Starts on </span>
                          <span class="dark-blue text-xs">{{ moment(frequency.startDate) }}</span>
                        </div>
                        <div v-else>
                          <span class="dark-blue text-xs mt-2 text-center block">Please select payment options first</span>
                        </div>
                      </div>
                      <div class="flex flex-col justify-center items-center">
                        <img :src="stopCircle" alt="stopcircle" />
                        <div v-if="frequency.endType">
                          <span class="dark-blue text-xs mt-2">Ends </span>
                          <span class="dark-blue text-xs mt-2" v-if="frequency.endType == 'after'">after {{ frequency.endPayments }} payments</span>
                          <span v-if="frequency.endType == 'by'" class="dark-blue text-xs mt-2">on {{ moment(frequency.endDate) }}</span>
                        </div>
                        <div v-else>
                          <span class="dark-blue text-xs mt-2 text-center block">Please select payment options first</span>
                        </div>
                      </div>
                    </vs-row>
                    <div class="vs-table--content">
                      <vs-row class="flex justify-between">
                        <span class="text-lg dark-blue">Recurring amount</span>
                        <span class="text-lg dark-blue" v-if="amount">$ {{ addZeroes(amount ? amount : "-") }}</span>
                        <span v-else>-</span>
                      </vs-row>
                      <vs-row class="flex justify-between mt-4">
                        <span class="text-lg dark-blue">Total amount</span>
                        <span class="text-lg dark-blue" v-if="amount">$ {{ addZeroes(amount ? amount : "-") }}</span>
                        <span v-else>-</span>
                      </vs-row>
                    </div>
                  </vs-col>
                </div>
              </vs-popup>
              <!-- popup ends -->
            </vs-collapse-item>
          </vs-collapse>
        </vs-card>

        <!-- payment options for recurring  -->
        <vs-card class="accordin-card mt-5 rec-overflow" v-if="isRecurring" @click="active = !active" :class="{ nooverf: active }">
          <vs-collapse accordion>
            <vs-collapse-item ref="paymentOptions" :open="true">
              <div slot="header">
                <h3 class="text-left font-normal">
                  Payment settings <span class="mid-blue text-base">*</span>
                </h3>
              </div>

              <template>
                <vs-row class="mb-5">
                  <vs-col vs-w="12">
                    <p class="font-normal">Configure your recurring payment settings.</p>
                  </vs-col>
                </vs-row>
                <vs-row class="flex-col mb-8">
                  <div>
                    <label class="font-normal text-base p-0 m-0 w-full">Starts</label>
                  </div>
                  <div>
                    <date-picker
                      v-validate="'required'"
                      name="startDate"
                      id="startDate"
                      v-model="frequency.startDate"
                      lang="en"
                      type="date"
                      placeholder="DD/MM/YYYY"
                      class="w-auto"
                      format="YYYY/MM/DD"
                      :disabled-days="(date) => date <= new Date()"
                    ></date-picker>
                    <span class="text-danger text-sm" v-show="errors.has('startDate')">{{ errors.first("startDate") }}</span>
                  </div>
                </vs-row>

                <vs-row class="flex-col mb-8">
                  <div>
                    <label class="font-normal w-full text-base">Repeat every</label>
                  </div>
                  <div class="flex">
                    <div class="mr-8 force-small-input">
                      <vs-input v-validate="'required'" id="interval" name="interval" v-model="frequency.frequency" />
                      <span class="text-danger text-sm" v-show="errors.has('interval')">{{ errors.first("interval") }}</span>
                    </div>
                    <div>
                      <vs-select v-validate="'required'" name="everySelect" id="everySelect" placeholder="Weeks" class="payment-setting-select" v-model="frequency.period" size="small">
                        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in everyOptions" />
                      </vs-select>
                      <span class="text-danger text-sm" v-show="errors.has('everySelect')">{{ errors.first("everySelect") }}</span>
                    </div>
                  </div>
                </vs-row>

                <vs-row class="my-8 flex-col">
                  <div class="mb-4">
                    <label class="font-normal text-base p-0 m-0">Ends</label>
                  </div>
                  <div class="flex items-center">
                    <div class="mr-16">
                      <vs-radio v-model="frequency.endType" v-validate="'required'" vs-name="endType" id="endType" name="endType" vs-value="by" class="flex">
                        <span class="block" style="color: #05053f; width: 50px">On</span>
                      </vs-radio>
                    </div>
                    <div>
                      <date-picker
                        name="endDate"
                        id="endDate"
                        v-model="frequency.endDate"
                        lang="en"
                        type="date"
                        placeholder="DD/MM/YYYY"
                        class="w-auto"
                        format="YYYY/MM/DD"
                        :disabled-days="(date) => date <= new Date()"
                      ></date-picker>
                    </div>
                  </div>
                </vs-row>
                <vs-row class="flex items-center">
                  <div class="mr-16">
                    <vs-radio v-model="frequency.endType" vs-name="endType" name="endType" vs-value="after" class="flex">
                      <span class="block" style="color: #05053f; width: 50px">After</span>
                    </vs-radio>
                  </div>
                  <div class="flex items-end">
                    <vs-input class="payments-width-force" v-model="frequency.endPayments" /><span class="ml-2">payments</span>
                  </div>
                </vs-row>
                <vs-row>
                  <div>
                    <span class="text-danger text-sm" v-show="errors.has('endType')">{{ errors.first("endType") }}</span>
                  </div>
                </vs-row>
              </template>
            </vs-collapse-item>
          </vs-collapse>
        </vs-card>

        <!-- payment options for recurring ends here -->
        <!-- Customer Details -->
        <vs-card class="accordin-card mt-5" v-if="productConfigType == 'THREE_PARTY'">
          <vs-collapse accordion>
            <vs-collapse-item ref="customerDetails" :open="true">
              <div slot="header">
                <h3 class="text-left font-normal font-normal">
                  Customer details <span class="mid-blue">*</span>
                </h3>
              </div>
              <customer-details :customerData="customerData" :isSubmitted="isSubmitted" :customTextData="customTextData" @checkForm="checkForm" ref="customerFields"></customer-details>
            </vs-collapse-item>
          </vs-collapse>
        </vs-card>
        <!-- custom fields -->
        <vs-card class="accordin-card mt-5" v-if="additionalBind && (productCustomFields.length > 0 || customFieldsArray.length > 0)">
          <vs-collapse accordion>
            <vs-collapse-item :open="!isCustomFieldEmpty || !isProductFieldEmpty" ref="customCollapse">
              <div slot="header">
                <h3 class="text-left font-normal">Additional Information <span class="mid-blue">*</span></h3>
              </div>
              <div>
                <div v-if="!isProductFieldEmpty">
                  <h4 class="text-lg">Product Fields</h4>
                  <hr class="line-hr mb-6 mt-4" />
                  <p class="lg:ml-6">This information is required from us related to the product being offered.</p>
                  <vs-row class="lg:ml-6">
                    <product-fields
                      :productCustomFields="productCustomFields"
                      :isSubmitted="isSubmitted"
                      :productFieldErrors="productFieldErrors"
                      @maxPercentageError="maxPercentageError"
                      @checkForm="checkForm"
                      v-if="productCustomFields.length > 0"
                      ref="productFields"
                    ></product-fields>
                  </vs-row>
                </div>
                <div v-if="!isCustomFieldEmpty">
                  <h4 class="text-lg mt-4">Custom Fields</h4>
                  <hr class="line-hr mb-6 mt-4" />
                  <p>This is information your business wants to collect relating to this request.</p>
                  <vs-row class="lg:ml-6">
                    <custom-fields @fileRemoved="changeCustomFieldArray" :customFieldsArray="customFieldsArray" :isSubmitted="isSubmitted" @checkForm="checkForm" v-if="customFieldsArray.length > 0" ref="customFields"></custom-fields>
                  </vs-row>
                </div>
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </vs-card>

        <!-- Attachments -->
        <vx-card class="accordin-card mt-5">
          <vs-collapse accordion>
            <vs-collapse-item ref="attachments">
              <div slot="header">
                <h3 class="text-left font-normal">Attachments</h3>
              </div>
              <attachment @updateFiles="fileUpdate"></attachment>
            </vs-collapse-item>
          </vs-collapse>
        </vx-card>

        <!-- Notification -->

        <vx-card class="accordin-card mt-5">
          <vs-collapse accordion>
            <vs-collapse-item ref="notification">
              <div slot="header">
                <h3 class="text-left font-normal">Notifications</h3>
              </div>
              <p>Notifications on status changes for this payment request will be sent to</p>
              <Notification
                :notification="this.requestTemplate.notifications"
                :userDetail="user"
                :notificationEmail="notificationEmail"
                :defaultNotificationEmails="defaultNotificationEmails"
                @checkForm="checkForm"
                v-if="notificationBind"
              />
            </vs-collapse-item>
          </vs-collapse>
        </vx-card>

        <!-- Sender Information -->
        <vx-card class="accordin-card mt-5" v-if="productConfigType == 'THREE_PARTY'">
          <vs-collapse accordion>
            <vs-collapse-item ref="senderInformation">
              <div slot="header">
                <h3 class="text-left font-normal">Contact details</h3>
              </div>
              <sender-information v-if="senderBind" :senderData="requestTemplate.senderDetails" @changeSenderDetail="changeSenderDetail" @checkForm="checkForm"></sender-information>
            </vs-collapse-item>
          </vs-collapse>
        </vx-card>

        <Terms @on="termsCallBack" @checkForm="checkForm" ref="termsValidation" v-if="showTermsCard" :termsDetail.sync="termsDetail"/>
      </vs-row>
      <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>
      <pop-up :errorsList="errors" form-name="paymentRequestForm"></pop-up>
      <vs-row>
        <div class="actions mt-5 text-right">
          <vs-button class="btn btn-primary lg:px-20 md:px-20 sm:px-10" v-round @click="sendPR">Send</vs-button>
          <span color="danger" @click="onCancel" class="btn pl-5 pr-5 ml-5 edit-profile" v-round>Cancel</span>
        </div>
      </vs-row>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import { Money } from "v-money";
import { RRule } from "rrule";
import moment from "moment";
import DatePicker from "vue2-datepicker";
const update = require("@/assets/images/update.png");
const playCircle = require("@/assets/images/play-circle.png");
const stopCircle = require("@/assets/images/stop-circle.png");
const calenderImage = require("@/assets/images/calender.png");

import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";

import CustomFields from "./components/customFieldsInPR.vue";
import ProductFields from "./components/productFieldsInPR.vue";

import CustomerDetails from "./CustomerDetails.vue";
import Attachment from "./Attachment.vue";
import SenderInformation from "./SenderInformation.vue";
import Notification from "./Notification.vue";
import { leavePopUpMixin } from "@/mixins/leave-pop-up";
import Terms from "./terms.vue";

const dictionary = {
  en: {
    attributes: {
      templateName: "template name",
      linkedAccount: "linked account",
    },
  },
};
Validator.localize(dictionary);
export default {
  mixins: [leavePopUpMixin],
  components: {
    LeavePopup,
    PopUp,
    CustomerDetails,
    Attachment,
    SenderInformation,
    Money,
    CustomFields,
    DatePicker,
    ProductFields,
    Notification,
    Terms,
  },
  data() {
    return {
      planDisplayName: "",
      totalCustomerPayable: "",
      merchantAmount: "",
      atLeastOneCForPFrequired: false,
      weekOrMonthOrDay: "",
      redirectData: {},
      customTextData: {},
      active: false,
      everyOptions: [
        { text: "Week", value: "week" },
        { text: "Month", value: "month" },
      ],
      frequency: {},
      isSaved: false,
      noFrequencyData: false,
      paymentSchedules: [],
      totalNumberOfPayments: 0,
      popupActive: false, // determines if the leave popup is to be shown
      showValidationPopUp: false, // determines if the leave popup is to be shown
      nextObj: "",
      addressData: "",
      planId: "",
      activePlan: {},
      payLaterSchedule: [],
      isProductFieldEmpty: false,
      isCustomFieldEmpty: false,
      defaultCustomFields: [],
      customFieldsArray: [],
      amount: "",
      money2: {
        precision: 2,
        prefix: "$ ",
      },
      reference: "",
      customFieldBind: "",
      popupActivo: false,
      hasPayNow: "",
      hasPayLater: "",
      isRecurring: "",
      reqOptions: [],
      reqTemplateId: "",
      requestTemplate: {},
      saveTemplate: "save",
      globalSettings: "",
      files: [],
      senderDetails: "",
      customerData: {
        customerName: "",
        customerEmail: "",
        customerMobile: "",
        customerMessage: "",
      },
      isSubmitted: false,
      defaultProductCustomFields: [],
      productCustomFields: [],
      senderBind: false,
      update: update,
      playCircle: playCircle,
      stopCircle: stopCircle,
      calenderImage: calenderImage,
      checkFormDirty: false,
      additionalBind: false,
      extraConditions: [],
      showExtraConditionError: false,
      errorMsg: "",
      notificationEmail: [],
      notificationBind: false,
      templateHeader: {},
      gracePeriod: "",
      isLoaded: false,
      defaultNotificationEmails: [],
      showWarning: false,
      productConfigType: 'THREE_PARTY',
      termsDetail: {},
      showTermsCard: false,
      productTerms: false,
      terms: "",
      privacy: "",
      productFieldErrors: [],
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
    },
    merchantId() {
      return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
    },
    rules() {
      if (!this.hasPayNow && (parseFloat(this.activePlan.minimumAmount) > 0 || parseFloat(this.activePlan.maximumAmount) > 0)) {
        return "required|payLaterAmountField";
      } else if (this.hasPayNow && this.hasPayLater && (parseFloat(this.activePlan.minimumAmount) > 0 || parseFloat(this.activePlan.maximumAmount) > 0)) {
        return "required|payNowAndLaterAmountField";
      } else {
        return "requiredAmount";
      }
    },
  },
  created() {
    this.reqTemplateId = this.$route.params.reqTemId;
    Validator.extend("payLaterAmountField", {
      getMessage: (_field) => {
        if (parseFloat(this.activePlan.minimumAmount) > 0 && this.amount < parseFloat(this.activePlan.minimumAmount)) {
          return "Amount must be above " + this.formatCurrency(this.activePlan.minimumAmount);
        } else if (parseFloat(this.activePlan.maximumAmount) > 0 && this.amount > parseFloat(this.activePlan.maximumAmount)) {
          return " Amount must be below " + this.formatCurrency(this.activePlan.maximumAmount);
        } else {
          return "Amount must be between " + this.formatCurrency(this.activePlan.minimumAmount) + " and " + this.formatCurrency(this.activePlan.maximumAmount);
        }
      },
      validate: (value) => value <= parseFloat(this.activePlan.maximumAmount) && value >= parseFloat(this.activePlan.minimumAmount),
    });
    Validator.extend("payNowAndLaterAmountField", {
      getMessage: (_field) => {
        if (parseFloat(this.activePlan.maximumAmount) > 0 && this.amount > parseFloat(this.activePlan.maximumAmount)) {
          return " Amount must be below " + this.formatCurrency(this.activePlan.maximumAmount);
        }
      },
      validate: (value) => value <= parseFloat(this.activePlan.maximumAmount),
    });
    Validator.extend("requiredAmount", {
      getMessage: (field) => "Amount field is required",
      validate: (value) => !!value,
    });
  },
  methods: {
    ...mapActions("paymentRequest", ["calculateOnPayLater", "getPlanByMerchantAndCustomPlanId", "storeNewPaymentRequest"]),

    maxPercentageError(err) {
      this.showExtraConditionError = true;
      this.scrollToElement();
    },

    scrollToElement() {
      const el = this.$refs.amount;
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }
    },

    ...mapActions("paymentRequestTemplate", ["fetchRequestTemplateByIdOnMerchant"]),
    ...mapActions("merchantGlobalSetting", ["fetchSettingsByMerchantId"]),
    ...mapActions("admin", ["checkLogin"]),

    addZeroes(num) {
      num = String(num);
      num.split(".")[1];
      return Number(num).toLocaleString(undefined, { minimumFractionDigits: 2 });
    },
    changeCustomFieldArray(value) {
      this.customFieldsArray[value.key].selectedFile = "";
    },

    async getRequestTemplateDetailById() {
      this.fetchRequestTemplateByIdOnMerchant(this.reqTemplateId).then(async (res) => {
        this.requestTemplate = res.data.data[0];
        this.customTextData = res.data.data[0].customText;
        this.senderDetails = this.requestTemplate.senderDetails || "";
        this.senderBind = true;

        if (this.requestTemplate.notifications.requestSenderEmail) {
          this.notificationEmail.push(this.user.email);
        } else {
          if (this.requestTemplate.notifications && this.requestTemplate.notifications.multipleEmailAddress) {
            this.notificationEmail = this.requestTemplate.notifications.multipleEmailAddress;
            this.defaultNotificationEmails = JSON.parse(JSON.stringify(this.requestTemplate.notifications.multipleEmailAddress));
          }
        }
        this.notificationBind = true;
        this.redirectData = res.data.data[0].redirect;

        if (this.requestTemplate.paymentOptions[0].requestOptions.includes("pay-now")) {
          this.hasPayNow = true;
        }

        if (this.requestTemplate.paymentOptions[0].requestOptions.includes("pay-later")) {
          this.hasPayLater = true;
        }

        if (this.requestTemplate.paymentOptions[0].requestType == "recurring") {
          this.isRecurring = true;
          this.$refs.customerDetails.maxHeight = "none !important";
        }
        if (this.requestTemplate.paymentOptions[0].requestType == "one-off") {
          this.isRecurring = false;
        }

        if (this.requestTemplate.customFields.length == 0) {
          this.isCustomFieldEmpty = true;
        } else {
          this.defaultCustomFields = this.requestTemplate.customFields;
          this.customFieldsArray = _.filter(
            this.defaultCustomFields,
            function (o) {
              return o.reqToSendRequest || o.editableByMerchant;
            }
          );
        }

        this.planId = this.requestTemplate.paymentOptions[0].payLaterPlan ? this.requestTemplate.paymentOptions[0].payLaterPlan[0] : "";

        if (this.requestTemplate.paymentOptions[0].planDisplayName) {
          this.planDisplayName = this.requestTemplate.paymentOptions[0].planDisplayName;
        }

        if (this.requestTemplate.header.isDefaultHeader === false) {
          this.templateHeader = { ...this.requestTemplate.header };
        }

        await this.fetchPlanByCustomFieldId();

        if (this.additionalBind) {
          if (this.$refs.customCollapse) {
            this.$refs.customCollapse.maxHeight = "none !important";
          }
        }
        this.isLoaded = true;

      }).catch(ex => {
        this.showMessage("Template", ex.response.data.message, "danger", "icon-times");
        this.$router.push({name : "partner-dashboard"});
      });
    },

    async fetchPlanByCustomFieldId() {
      const payload = {
        merchantId: this.merchantId,
        customPlanId: this.planId,
      }
      await this.getPlanByMerchantAndCustomPlanId(payload).then((res) => {
        if (res) {
          const result = res.data.data[0].plan;
          this.gracePeriod = result.gracePeriod || 0;
          if (result.extraConditions.length > 0) {
            this.extraConditions = result.extraConditions ? result.extraConditions : [];
            this.errorMsg = this.extraConditions[0].errorMsg;
          }

          if (result.customFields.length == 0) {
            this.isProductFieldEmpty = true;
          }
          this.activePlan = {
            _id: result._id,
            paymentFrequency: result.paymentFrequency,
            feeModel: result.feeModel,
            msf: res.data.data[0].msf || result.msf,
            minimumMsfFeeAmount: result.minimumMsfFeeAmount || 0,
            noOfPayments: result.noOfPayments,
            maximumAmount: result.maximumAmount,
            minimumAmount: result.minimumAmount,
            customFields: result.customFields,
            productType: result.productType,
            productName: result.productName,
            initialPeriod: result.initialPeriod ? result.initialPeriod : null,
            planDisplayName: this.planDisplayName,
            productText: result.productText,
          };
          this.productConfigType = result.productConfigType;
          this.defaultProductCustomFields = this.activePlan.customFields;
          this.productCustomFields = _.filter(
            this.defaultProductCustomFields,
            function (o) {
              return o.reqToSendRequest || o.editableByMerchant;
            }
          ).map((row) => ({
            ...row,
            addr: {
              unit_number: "",
              street_number: "",
              street_name: "",
              suburb: "",
              state: "",
              street_type: "",
              postcode: "",
              country: "",
            },
          }));
          this.additionalBind = true;
          this.$refs.customerDetails.maxHeight = "none !important";

          if (["B2C_BNPO", "B2C_BNPO_NC"].includes(result.productType) && result.productConfigType == "TWO_PARTY") {
            this.showTermsCard = true;
            this.termsDetail = result.legals;
          }
        }
      }).catch((ex) => {
        this.isProductFieldEmpty = true;
        this.additionalBind = true;
        this.$refs.customerDetails.maxHeight = "none !important";
      });
    },

    async openPopUp(requestType) {
      this.popupActivo = true;

      if (requestType == "paylater") {
        await this.calculateOnPayLater({ planId: this.activePlan._id, amount: this.amount > 0 ? this.amount : null }).then((result) => {
          const total = result.data.data.find(item => item.type == "Total");
          const disbursement = result.data.data.find(item => item.type == "Disbursement");
          const schedule = result.data.data.filter(item => item.type == "Payment");

          if (this.activePlan.productType == "B2C_BNPL") {
            this.payLaterSchedule = schedule.map((item) => {
              return {
                label: item.amount,
                value: new Date(item.date),
              };
            });
            this.paymentSchedules = this.payLaterSchedule;
            this.totalNumberOfPayments = this.payLaterSchedule.length;
          }

          this.totalCustomerPayable = total.amount;
          this.merchantAmount = disbursement.amount;
        });
      }

      if (requestType == "recurring") {
        this.prepareSchedule();
      }

      //for finding week month or day
      if (this.activePlan.paymentFrequency) {
        switch (this.activePlan.paymentFrequency) {
          case "DAILY":
            this.weekOrMonthOrDay = "Day";
            break;
          case "FORTNIGHTLY":
            this.weekOrMonthOrDay = "fortnight";
            break;
          case "MONTHLY":
            this.weekOrMonthOrDay = "Month";
            break;
          case "WEEKLY":
            this.weekOrMonthOrDay = "Week";
            break;
        }
      }
    },

    prepareSchedule() {
      const startDateWithDay = new Date(this.frequency.startDate);
      let rule = { dtstart: this.frequency.startDate ? startDateWithDay : new Date() };

      if (this.frequency.period === "week") {
        rule.freq = RRule.WEEKLY;
        rule.interval = this.frequency.frequency ? parseInt(this.frequency.frequency) : 0;
      } else {
        rule.freq = RRule.MONTHLY;
        rule.interval = this.frequency.frequency ? parseInt(this.frequency.frequency) : 0;
      }

      if (this.frequency.endType === "by") {
        const endDateWithDay = new Date(this.frequency.endDate);
        rule.until = this.frequency.endDate ? endDateWithDay : new Date();
      } else {
        rule.count = this.frequency.endPayments ? parseInt(this.frequency.endPayments) : 1;
      }
      this.installment(rule);
    },

    installment(payload) {
      const rule = new RRule(payload);
      this.paymentList(rule.all());
    },

    paymentList(schedule) {
      let list = [];
      for (let i = 0; i < schedule.length; i++) {
        list.push({
          label: this.amount,
          value: schedule[i],
        });
      }
      this.totalNumberOfPayments = schedule.length;

      this.paymentSchedules = list;
    },

    moment(date) {
      return moment(date).format("D-MMM-YY");
    },
    formatScheduleDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    getWeekDay(date) {
      return moment(date).format("dddd");
    },

    //! send payment request
    async sendPR() {
      try {
        if (this.amount <= 0.00) {
          this.errors.add({
            field: 'amount',
            msg: "Amount must be greater than 0.00"
          })
          return;
        }

        if (this.errors.items.length > 0) {
          this.errors.items = [];
        }

        this.isSubmitted = true;
        let customValid = true;
        let productValid = true;
        let customerValid = true;
        let termsValid = true;
        const valid = await this.$validator.validate();

        if (this.$refs.customerFields) {
          customerValid = await this.$refs.customerFields.validateChildForm();
        }
        if (this.$refs.productFields) {
          productValid = await this.$refs.productFields.validateChildForm();
        }
        if (this.$refs.customFields) {
          customValid = await this.$refs.customFields.validateChildForm();
        }

        if (this.$refs.customerDetails) {
          this.$refs.customerDetails.maxHeight = "none !important";
        }

        if (this.$refs.termsValidation) {
          termsValid = await this.$refs.termsValidation.validateChildForm();
        }

        if (this.$refs.customerFields && this.customerData.customerName == "") {
          return false;
        }

        if (this.$refs.customerFields && this.customerData.customerEmail == "" && this.customerData.customerMobile == "") {
          return false;
        }

        if (this.errors.items.length > 0) {
          if (this.$refs.customerDetails) {
            this.$refs.customerDetails.maxHeight = "none !important";
          }
          if (this.$refs.customCollapse) {
            this.$refs.customCollapse.maxHeight = "none !important";
          }
          return false;
        }

        if (!valid || !customValid || !productValid || !customerValid || !termsValid) {
          return false;
        } else {
          const formData = new FormData();

          if (this.user && this.user.adminId && this.user.loginBy) {
            formData.append("createdByAdmin", this.user.adminId);
          }

          for (let i = 0; i < this.files.length; i++) {
            formData.append("file", this.files[i]);
          }

          formData.append("amount", this.amount);
          formData.append("reference", this.reference);
          formData.append("paymentRequestTemplate", this.requestTemplate._id);
          formData.append("payeeName", this.customerData.customerName);
          formData.append("payeeEmail", this.customerData.customerEmail);
          formData.append("payeePhone", this.customerData.customerMobile);
          formData.append("message", this.customerData.customerMessage);

          formData.append("gracePeriod", this.gracePeriod);

          if (this.redirectData) {
            let paymentPage = {
              payLaterRedirect: {
                successButtonText: this.redirectData.success.btntext,
                successUrl: this.redirectData.success.link,
                successBody: this.redirectData.success.message,
                successHeading: this.redirectData.success.heading,
                failureUrl: this.redirectData.fail.link,
                failureButtonText: this.redirectData.fail.btntext,
                failureBody: this.redirectData.fail.message,
                failureHeading: this.redirectData.fail.heading,
              },
              accountId:this.requestTemplate.linkedAccount.connectorId || '',
              bankId:this.requestTemplate.linkedAccount.bankId || ''
            }
            formData.append("paymentPage", JSON.stringify(paymentPage));
            formData.append("successUrl", this.redirectData.success.link);
            formData.append("successButtonText", this.redirectData.success.btntext);
            formData.append("successDisplayText", this.redirectData.success.btntext);
            formData.append("successHeading", this.redirectData.success.heading);
            formData.append("successBody", this.redirectData.success.message);
            formData.append("failureUrl", this.redirectData.fail.link);
            formData.append("failureButtonText", this.redirectData.fail.btntext);
            formData.append("failureDisplayText", this.redirectData.fail.btntext);
            formData.append("failureHeading", this.redirectData.fail.heading);
            formData.append("failureBody", this.redirectData.fail.message);
          }

          const customerDetail = {
            customerName: this.customerData.customerName,
            customerMobile: this.customerData.customerMobile,
            customerEmail: this.customerData.customerEmail,
          };

          formData.append("customerDetail", JSON.stringify(customerDetail));
          formData.append("senderDetails", JSON.stringify(this.senderDetails));
          formData.append("notificationEmail", JSON.stringify(this.notificationEmail));

          if (this.showTermsCard) {
            formData.append("terms", this.terms);
            formData.append("privacy", this.privacy);
          }

          if (this.requestTemplate.header) {
            formData.append("header", JSON.stringify(this.templateHeader));
          }

          if (this.requestTemplate.linkedAccount) {
            formData.append("bankId", this.requestTemplate.linkedAccount.bankId);
          }

          formData.append("requestType", this.requestTemplate.paymentOptions[0].requestType);

          if (this.requestTemplate.header.companyLogo) {
            formData.append("companyLogo", this.requestTemplate.header.companyLogo.logoImage);
          }

          if (this.requestTemplate.requestExpiry) {
            formData.append("paymentExpiry", this.requestTemplate.requestExpiry.paymentExpiry);
          }

          if (this.requestTemplate.paymentOptions[0].payLaterPlan) {
            formData.append("plans", JSON.stringify(this.requestTemplate.paymentOptions[0].payLaterPlan));
          }

          if (this.requestTemplate.paymentOptions[0].requestOptions) {
            let requestOptionsArray = this.requestTemplate.paymentOptions[0].requestOptions;

            if (this.requestTemplate.paymentOptions[0].requestOptions.includes("pay-later") && this.amount < this.activePlan.minimumAmount) {
              requestOptionsArray = this.requestTemplate.paymentOptions[0].requestOptions.filter((e) => { return e !== "pay-later" });
            }

            formData.append("requestOptions", JSON.stringify(requestOptionsArray));
          }

          formData.append("partnerId", this.requestTemplate.merchantId);

          if (this.requestTemplate.paymentOptions[0].requestType == "recurring") {
            let frequency = {
              every: this.frequency.period,
              interval: this.frequency.frequency,
              startDate: this.frequency.startDate,
              endType: this.frequency.endType,
              endDate: this.frequency.endDate ? this.frequency.endDate : "",
              endAfterNumberOfPayments: this.frequency.endPayments ? this.frequency.endPayments : "",
            };
            formData.append("frequency", JSON.stringify(frequency));
          }

          if (this.defaultCustomFields.length > 0) {
            let field = this.defaultCustomFields.map((item) => {
              let defaultField = this.customFieldsArray.filter((filterItem) => {
                return item._id == filterItem._id;
              });

              if (item.type==='date') {
                item.value = item.value ? moment(item.value).format("YYYY-MM-DD") : "";
              }

              return {
                label: item.label,
                type: item.type,
                value: defaultField.length > 0 && defaultField[0].value ? defaultField[0].value : "",
                displayToCustomer: item.displayToCustomer,
                editableByCustomer: item.editableByCustomer,
                requiredToSubmit: item.requiredToSubmit,
                editableByMerchant: item.editableByMerchant,
                reqToSendRequest: item.reqToSendRequest,
                customFieldId: item.customFieldId,
                dropdownSelectFields: item.dropdownSelectFields ? item.dropdownSelectFields : [],
              };
            });

            formData.append("customFields", JSON.stringify(field));

            const fileInCustomFields = this.defaultCustomFields.filter(
              (field) => {
                return (field.type === "file" && field.hasOwnProperty("selectedFile"));
              }
            );

            if (fileInCustomFields.length > 0) {
              for (let index = 0; index < fileInCustomFields.length; index++) {
                formData.append("customFiles", fileInCustomFields[index].selectedFile, `${fileInCustomFields[index].label.split(" ").join("-").split("/").join("-")}@@${fileInCustomFields[index].selectedFile.name}`);
              }
            }
          }

          if (this.defaultProductCustomFields.length > 0) {
            let productField = this.defaultProductCustomFields.map((item) => {
              let defaultField = this.productCustomFields.filter((filterItem) => { return item._id == filterItem._id; });
              let fieldValue = "";

              if (defaultField.length > 0) {
                if (item.type == "address") {
                  fieldValue = defaultField[0].addr.hasOwnProperty("street_name") && defaultField[0].addr.street_name.length > 0 ? JSON.stringify(defaultField[0].addr) : "";
                } else if (item.type == "date") {
                  fieldValue = defaultField[0].value ? moment(defaultField[0].value).format("YYYY-MM-DD") : "";
                } else {
                  fieldValue = defaultField[0].value ? defaultField[0].value : "";
                }
              }

              return {
                label: item.label,
                type: item.type,
                value: fieldValue,
                displayToCustomer: item.displayToCustomer,
                editableByCustomer: item.editableByCustomer,
                requiredToSubmit: item.requiredToSubmit,
                editableByMerchant: item.editableByMerchant,
                reqToSendRequest: item.reqToSendRequest,
                displayToCustomerAfter: item.displayToCustomerAfter,
                editableByCustomerAfter: item.editableByCustomerAfter,
                displayToMerchantAfter: item.displayToMerchantAfter,
                editableByMerchantAfter: item.editableByMerchantAfter,
                customFieldId: item.customFieldId,
                dropdownSelectFields: item.dropdownSelectFields ? item.dropdownSelectFields : [],
                validationWhenSendingRequests: item.validationWhenSendingRequests ? item.validationWhenSendingRequests : [],
              };
            });

            formData.append("productCustomFields", JSON.stringify(productField));
            const fileInProductFields = this.productCustomFields.filter((field) => { return (field.type === "file" && field.hasOwnProperty("selectedFile")); });
            for (let index = 0; index < fileInProductFields.length; index++) {
              formData.append("productFiles", fileInProductFields[index].selectedFile, `${fileInProductFields[index].label.split(" ").join("-").split("/").join("-")}@@${fileInProductFields[index].selectedFile.name}`);
            }
          }

          if (this.requestTemplate.notifications.prNotification) {
            let prStatus = {};
            this.requestTemplate.notifications.prNotification.map((item) => {
              let status = this.checkNotification(item);
              prStatus[status] = item.forEmail;
            });
            formData.append("prStatus", JSON.stringify({ ...prStatus }));
          }

          if (this.requestTemplate.notifications.txNotification) {
            let txStatus = {};
            this.requestTemplate.notifications.txNotification.map((item) => {
              let status = this.checkNotification(item);
              txStatus[status] = item.forEmail;
            });
            formData.append("txStatus", JSON.stringify({ ...txStatus }));
          }

          let obj = {
            obj: formData,
            config: {
              header: {
                "Content-Type": "application/json",
              },
            },
          };
          this.showWarning = false;
          this.$vs.loading();

          await this.storeNewPaymentRequest(obj).then((c) => {
            this.$vs.loading.close();
            this.popupActive = false;
            this.showMessage("Success", "Payment request created successfully.", "success", "icon-check-circle");
            this.to = "partner-dashboard";
            this.isSaved = true;
            this.$router.push({ name: "partner-dashboard" });
          }).catch((ex) => {
            this.$vs.loading.close();

            if (ex.data && ex.data.message && Array.isArray(ex.data.message)) {
              this.productFieldErrors = ex.data.message;
            } else {
              this.showMessage("Error", ex.data.message, "danger", "icon-times");
            }
          });
        }
      } catch (ex) {
        this.$vs.loading.close();
      }
    },

    checkNotification(notify) {
      let statusLabel = "";
      if (notify.status == "Active") {
        statusLabel = "active";
      } else if (notify.status == "Expired") {
        statusLabel = "expired";
      } else if (notify.status == "Re-sent") {
        statusLabel = "resent";
      } else if (notify.status == "Cancelled") {
        statusLabel = "cancelled";
      } else if (notify.status == "Complete") {
        statusLabel = "complete";
      } else if (notify.status == "Success") {
        statusLabel = "success";
      } else if (notify.status == "Fail") {
        statusLabel = "fail";
      } else if (notify.status == "Schedule registered") {
        statusLabel = "scheduleRegistered";
      } else if (notify.status == "Schedule registration failed") {
        statusLabel = "scheduleFailed";
      } else if (notify.status == "ID verification failed") {
        statusLabel = "idFailed";
      }

      return statusLabel;
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    onCancel() {
      this.$router.push({ name: "partner-dashboard" });
    },
    changeSenderDetail(senderData) {
      this.senderDetails = senderData;
    },

    fileUpdate(files) {
      this.files = files;
      this.$refs.attachments.maxHeight = "none !important";
      this.checkFormDirty = true;
    },
    checkForm() {
      this.checkFormDirty = true;
    },
    termsCallBack(data) {
      this.errors.clear();
      if (data.val) {
        this.productTerms = data.val;
        this.terms = data.terms;
        this.privacy = data.privacy;
      } else {
        this.errors.add({
          field: "productTerm",
          message: "Terms and Conditions are required."
        });
      }
    },
    handleAddress() {},
    async getGlobalSetting() {
      await this.fetchSettingsByMerchantId().then(async (res) => {
        if (res.data.data.companyHeader) {
          this.templateHeader = { ...res.data.data.companyHeader };
          this.templateHeader.isDefaultHeader = true;
        }
      });
    },
    async checkAdminLogin() {
      await this.checkLogin();
    },
    showWarningMessage(title = 'Warning', message='Warning', autoClose = false) {
      this.$toast.warning(<div class="container" id="custom-message"><h3>{title}</h3><p>{message}</p></div>, {
        position: "bottom-right",
        timeout: autoClose ? 3000 : false,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false,
        draggablePercent: 0.1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: {
          iconClass: "material-icons text-warning",
          iconChildren: "info_outline",
          iconTag: "span"
        },
        rtl: false,
        message: message
      });
    },
    closeWarningMessage(id) {
      this.$toast.clear(id);
    }
  },

  beforeRouteLeave(to, from, next) {
    if ((this.isFormDirty || this.checkFormDirty) && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      next();
    }
  },

  async beforeMount() {
    await this.getGlobalSetting();
    await this.getRequestTemplateDetailById();
    this.checkAdminLogin();
  },

  watch: {
    customerData: {
      handler: function (after, before) {
        if (this.isLoaded) {
          this.$refs.customerDetails.maxHeight = "none !important";
          this.$refs.requestDetails.maxHeight = "none !important";
        }
      },
      deep: true,
    },

    frequency: {
      handler: function (after, before) {
        if (this.isLoaded) {
          this.$refs.paymentOptions.maxHeight = "none !important";
        }
      },
      deep: true,
    },

    checkFormDirty(latest, previous) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    isFormDirty(latest, previous) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    isSaved(latest, previous) {
      if (!latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    showWarning(newVal) {
      if (newVal && this.hasPayNow && this.hasPayLater) {
        this.showWarningMessage("Pay Later can't be offered", "Pay Later is not available for the request amount. If you choose to proceed, the payment request will be sent requesting the customer to Pay Now.");
      } else {
        this.closeWarningMessage("custom-message");
      }
    },

    amount(newVal) {
      return this.showWarning = newVal && newVal > 0 && newVal < parseFloat(this.activePlan.minimumAmount);
    }
  },
};
</script>