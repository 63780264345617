<template>
  <div>
    <div class="mb-8">
      <p>
        It is mandatory to enter customer’s email OR mobile phone to send
        request. You may fill out both fields to send request via multiple
        methods.
      </p>
      <!-- {{customTextData}} -->
    </div>
    <div>
      <div>
      <div class="input-grp">
        <div class="mb-6">
          <label class="label w-full">Full name <span class="mid-blue">*</span></label>
          <vs-input
            class="w-full"
            size="large"
            v-model="customerData.customerName"
            name="customerName"
            id="customerName"
            v-validate="'required'"
            @input="checkForm"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('customerName')"
            >{{ errors.first("customerName") }}</span
          >
        </div>
      </div>
        <div class="flex" :class="customTextData.allowOverride || customTextData.body!=='' ? 'flex-col': 'flex-row'">
      <div class="input-grp">
        <div class="mb-6">
          <label class="label w-full">Email</label>
          <vs-input
            class="w-full"
            size="large"
            v-model="customerData.customerEmail"
            name="customerEmail"
            id="customerEmail"
            v-validate="emailRules"
            @input="checkForm"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('customerEmail')"
            >{{ errors.first("customerEmail") }}</span
          >
        </div>
      </div>
      <div class="input-grp">
        <div class="mb-6">
          <label class="w-full">Mobile phone</label>
          <the-mask
            :class="
              customerData.customerMobile
                ? 'vs-inputx vs-input--input large'
                : 'vs-inputx vs-input--input large'
            "
            :mask="['#### ### ###']"
            v-model="customerData.customerMobile"
            masked
            name="customerMobile"
            ref="customerMobile"
            id="customerMobile"
            @input="checkForm"
            v-validate="{
              required: false,
              regex: /^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3}$/,
            }"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('customerMobile')"
            >{{ errors.first("customerMobile") }}</span
          >
        </div>
      </div>
      </div>
      </div>
      <div class="input-grp" v-if="customTextData.allowOverride || customTextData.body!==''" >
        <div>
          <label class="label w-full">Message to customer</label>
          <vs-textarea
            counter="250"
            maxlength="250"
            v-if="customTextData.allowOverride"
            class="custom-textarea"
            v-model="customerData.customerMessage"
            id="custom-textarea"
            @input="auto_grow('custom-textarea')"
          />
          <p v-else>{{ customTextData.body }}</p>

          <!-- {{customerData.customerMessage}} -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Validator } from "vee-validate";
import { TheMask } from "vue-the-mask";
const dictionary = {
  en: {
    attributes: {
      customerName: "customer name",
      customerEmail: "customer email",
      customerMobile: "customer mobile",
    },
  },
};
Validator.localize(dictionary);
export default {
  components: { TheMask },
  props: ["customerData", "isSubmitted", "customTextData"],
  data() {
    return {
      // customerName: "",
      // customerEmail: "",
      // customerMobile: "",
      // customerMessage: ""
    };
  },
  updated() {
    this.auto_grow("custom-textarea");
  },
  mounted() {
    this.a = this.customerData;
    
  },
  watch: {
    async isSubmitted(value) {
      
      this.startValidating();
    },

    customTextData(val) {
      
      this.customerData.customerMessage = val.body;
    },
  },
  computed: {
    emailRules() {
      return this.customerData.customerMobile === "" ? "required|email" : "";
    },
  },
  methods: {
    async startValidating() {
      await this.$validator.validate();
    },
    auto_grow(elem) {
      let element = document.getElementById(elem);
      if(element){
        element.style.height = "5px";
        element.style.height = (element.scrollHeight)+"px";
      }
      this.checkForm();
    },
    checkForm(){
      this.$emit("checkForm");
    },
    validateChildForm() {
      return this.$validator.validateAll();
    },
  },
};
</script>

