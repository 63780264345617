<template>
    <div>
      <vs-row class="sender-info" v-if="notification.requestSenderEmail===true">
        <div>
          <div class="radio-card mt-5 active">
            <vs-row>
              <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">
                To me
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                <div>
                  <p class="radio-info">Email: {{userDetail.email}} </p>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </vs-row>

      <vs-row class="sender-info" v-if="notification.requestSenderEmail === false">
        <div>
          <div class="radio-card mt-5 mr-6 active">   
            <vs-row>
              <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">
                <p class="text-xl">Default</p> 
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                <div>
                  <p class="radio-info">Email: {{getEmail}}</p>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </div>

        <div>
          <div class="radio-card mt-5" :class="{ active: toMe === true }">
            <vs-row>
              <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">
                {{ notification.requestSenderEmail === false ? 'Also send to me' : 'To Me' }} 
              </vs-col>
              <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex justify-center">
                <vs-checkbox :vs-value=1 v-model="toMe" @change="activate"></vs-checkbox>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                <div>
                  <p class="radio-info">Email: {{userDetail.email}}</p>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </vs-row>

    </div>
</template>

<script>
export default {
  name: "Notification",
  props:{
    notification: {type:Object},
    userDetail: {type:Object},
    notificationEmail: {type:Array},
    defaultNotificationEmails: {type:Array}
  },
  data(){
    return{
      toMe: false
    }
  },
  methods:{
    activate() {
      if (this.toMe === true) {
        this.notificationEmail.push(this.userDetail.email);
      } else {
        let index = this.notification.multipleEmailAddress ? (this.notification.multipleEmailAddress.length - 1) : 1;
        this.notificationEmail.splice(index,1);
      }
      this.$emit("checkForm");
    }
  },
  
  computed:{
    getEmail() {
      let email = '';
      if (this.defaultNotificationEmails) {
        this.defaultNotificationEmails.forEach((element, key) => {
          email+= `${element}${key < (this.defaultNotificationEmails.length - 1) ? ', ' :''}`;
        });
        return email;
      }
      return notification.emailAddress;
      }
  }

}
</script>
