<template>
  <div>
    <vs-row>
      <p>
        You can attach up to 3 PDF files (they’ll be accessible to your Customer
        when they view this payment request)
      </p>
    </vs-row>
    <vs-row>
      <vs-col vs-w="4" vs-lg="12" vs-sm="12" class="lg:mr-10">
        <div class="mb-5 mt-5">
          <div style="width: calc(100% - 135px)" class="upload-wrap">
            <ul class="attach-lists">
              <li
                class="items-center flex mb-5"
                v-for="(attach, key) in files"
                :key="key"
              >
                <p class="underline font-normal text-blue-light">
                  {{ attach.name }}
                </p>
                <a @click="removeFiles(key)" class="ml-10"
                  ><svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.6665 5.79606C1.6665 5.33582 2.0396 4.96273 2.49984 4.96273H17.4998C17.9601 4.96273 18.3332 5.33582 18.3332 5.79606C18.3332 6.2563 17.9601 6.62939 17.4998 6.62939H2.49984C2.0396 6.62939 1.6665 6.2563 1.6665 5.79606Z"
                      fill="#757575"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.33317 3.29606C8.11216 3.29606 7.9002 3.38386 7.74392 3.54014C7.58763 3.69642 7.49984 3.90838 7.49984 4.12939V4.96273H12.4998V4.12939C12.4998 3.90838 12.412 3.69642 12.2558 3.54014C12.0995 3.38386 11.8875 3.29606 11.6665 3.29606H8.33317ZM14.1665 4.96273V4.12939C14.1665 3.46635 13.9031 2.83047 13.4343 2.36163C12.9654 1.89279 12.3295 1.62939 11.6665 1.62939H8.33317C7.67013 1.62939 7.03425 1.89279 6.5654 2.36163C6.09656 2.83047 5.83317 3.46635 5.83317 4.12939V4.96273H4.1665C3.70627 4.96273 3.33317 5.33582 3.33317 5.79606V17.4627C3.33317 18.1258 3.59656 18.7617 4.0654 19.2305C4.53424 19.6993 5.17013 19.9627 5.83317 19.9627H14.1665C14.8295 19.9627 15.4654 19.6993 15.9343 19.2305C16.4031 18.7617 16.6665 18.1258 16.6665 17.4627V5.79606C16.6665 5.33582 16.2934 4.96273 15.8332 4.96273H14.1665ZM4.99984 6.62939V17.4627C4.99984 17.6837 5.08763 17.8957 5.24392 18.052C5.4002 18.2083 5.61216 18.2961 5.83317 18.2961H14.1665C14.3875 18.2961 14.5995 18.2083 14.7558 18.052C14.912 17.8957 14.9998 17.6837 14.9998 17.4627V6.62939H4.99984Z"
                      fill="#757575"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.33317 9.12939C8.79341 9.12939 9.1665 9.50249 9.1665 9.96273V14.9627C9.1665 15.423 8.79341 15.7961 8.33317 15.7961C7.87293 15.7961 7.49984 15.423 7.49984 14.9627V9.96273C7.49984 9.50249 7.87293 9.12939 8.33317 9.12939Z"
                      fill="#757575"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.6665 9.12939C12.1267 9.12939 12.4998 9.50249 12.4998 9.96273V14.9627C12.4998 15.423 12.1267 15.7961 11.6665 15.7961C11.2063 15.7961 10.8332 15.423 10.8332 14.9627V9.96273C10.8332 9.50249 11.2063 9.12939 11.6665 9.12939Z"
                      fill="#757575"
                    />
                  </svg>
                </a>
              </li>
            </ul>
            <span class="text-danger text-sm" v-show="errors.has(`file`)">{{ errors.first(`file`) }}</span>
          </div>
        </div>
        <div class="mb-5 mt-5">
          <input
            type="file"
            class="hidden"
            name="file"
            ref="uploadImgInput"
            v-validate="{ size: maxFileSize * 1024 }"
            multiple
            @change="updateCurrImg"
            accept=".pdf"
          />

          <vs-button
            v-if="files.length < 3"
            class="only-border-btn"
            v-round
            style="height: 30px; min-width: 126px"
            size="medium"
            @click="$refs.uploadImgInput.click()"
            :disabled="!validateForm"
            >Upload file</vs-button>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      files: [],
      fileMoreThanThree: false,
      maxFileSize: process.env.VUE_APP_FILE_MAX_SIZE_MB,
    };
  },
  methods: {
    updateCurrImg(input) {
      if (input.target.files.length <= 3) {
        if (input.target.files && input.target.files[0]) {
          let files = input.target.files;
          let self = this;
          if (this.files.length + files.length > 3) {
            this.showMessage(
              "Failed",
              "Maximum 3 files allowed.",
              "danger",
              "icon-times"
            );
          } else {
            if (files.length > 1) {
              files.forEach(function (file) {
                self.files.push(file);
              });
            } else {
              this.files.push(files[0]);
            }
            this.$emit("updateFiles", this.files);
            if (!this.isFileValid() && !this.errors.has(`file`)) {
              this.errors.add({
                field: "file",
                msg: `The file size must be less than ${this.maxFileSize} MB`,
              });
            }
          }
        }
      } else {
        this.showMessage(
          "Failed",
          "Maximum 3 files allowed.",
          "danger",
          "icon-times"
        );
      }
    },
    removeFiles(key) {
      this.files.splice(key, 1);
      this.$emit("updateFiles", this.files);
      this.$refs.uploadImgInput.value = "";
      if (this.errors.has(`file`) && this.isFileValid()) {
        this.errors.remove(`file`);
      }
    },
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },
    isFileValid() {
      let valid = true;
      this.files.forEach(element => {
        if (element.size > Number(this.maxFileSize * 1024 * 1024)) {
          valid = false;
        }
      });
      return valid;
    }
  },
  watch: {
    files(value) {
      if (value.length >= 3) {
        this.fileMoreThanThree = true;
      }
    },
  },
};
</script>
