<template>
  <div>
    <vs-row>
      <p>
        The following contact details will be displayed on this payment request
        </p>
    </vs-row>

<!-- {{senderData}} -->
    <vs-row class="sender-info">
      <div>
        <div
          class="radio-card mt-5 mr-6"
          @click="activate('default')"
          :class="{ active: active_el == 'default' }"
        >
          <vs-row>
            <vs-col
              vs-w="10"
              vs-sm="10"
              vs-lg="10"
              vs-xs="10"
              class="radio-label"
            >
              Default
            </vs-col>
            <vs-col
              vs-w="2"
              vs-sm="2"
              vs-lg="2"
              vs-xs="2"
              class="flex justify-center"
            >
              <vs-radio v-model="senderType" vs-value="default" vs-name="user">
              </vs-radio>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
              <div>
                <p class="radio-info">Name: {{ senderData.fullname }}</p>
                <p class="radio-info my-2">Email: {{ senderData.senderEmail }}</p>
                <p class="radio-info">Phone: {{ senderData.senderPhone }}</p>
              </div>
            </vs-col>
          </vs-row>
        </div>
      </div>
      <div>
        <div v-if="senderData.allowOverride"
             class="radio-card mt-5"
             @click="e => overrideDefault && activate('sendFromMe')"
             :class="{ active: active_el == 'sendFromMe' }"
        >
          <vs-row>
            <vs-col
              vs-w="10"
              vs-sm="10"
              vs-lg="10"
              vs-xs="10"
              class="radio-label"
            >
              Send from me
            </vs-col>
            <vs-col
              vs-w="2"
              vs-sm="2"
              vs-lg="2"
              vs-xs="2"
              class="flex justify-center"
            >
              <vs-radio
                v-model="senderType"
                vs-value="sendFromMe"
                vs-name="user"
                :disabled="!overrideDefault"
              >
              </vs-radio>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
              <div>
                <p class="radio-info">Name: {{ this.user.fullName }}</p>
                <p class="radio-info my-2">Email: {{ this.user.email }}</p>
                <p class="radio-info">Phone: {{ this.user.phoneNumber }}</p>
              </div>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </vs-row>
  </div>
</template>
<script lang="ts">
export default {
  props: ["senderData"],
  data() {
    return {
      senderType: "default",
      active_el: "",
      overrideDefault: true,
      senderDisabled: true,
      displayOverride: true
    };
  },
  methods: {
    activate(el) {
      this.active_el = el;
      this.senderType = el;
      if (el == "default") {
        this.senderDisabled = true;
      } else {
        this.senderDisabled = false;
      }
      this.$emit(
        "changeSenderDetail",
        el == "default"
          ? this.senderData
          : {
            fullname: this.user.fullName,
            senderEmail: this.user.email,
            senderPhone: this.user.phoneNumber,
            type: "sendFromMe"
          }
      );
      this.$emit("checkForm");
    }
  },
  mounted() {
    this.overrideDefault = this.senderData.allowOverride;
    this.senderType = "default";
    this.active_el = "default";
    this.$emit("changeSenderDetail", this.senderData);
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    }
  }
};
</script>